import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';
import {
  withRouter, Link, Router, Switch, Route,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';

import LogoBlack from '~images/Logo_header_black.png';
import LogoWhiteSanta from '~images/Logo_header_white_santa.png';
import { showSuccess, showError } from '~utils/toast';
import { MIN_WIDTH_LOGIN } from '~utils/constants';
import { reduxOperations } from '~services';
import { getFormData } from '~utils/index';

import LoginPage from './LoginPage';
import Footer from '~components/Pages/Footer';

const history = createBrowserHistory();

const propTypes = {
  // From the redux store
  t: PropTypes.func.isRequired,
  updateIsSignup: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
};

const loginRouter = () => (
  <Switch>
    <Route path="/" component={LoginPage} />
  </Switch>
);

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignupCollapsed: false,
      userCreated: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => this.setState({
    isSignupCollapsed: window.innerWidth < MIN_WIDTH_LOGIN,
  });

  handleSignup = () => {
    const { updateIsSignup } = this.props;
    updateIsSignup(false);
  };

  submitForm = async () => {
    const formData = getFormData('Signup__inner__form');
    const { addUser, t, updateIsSignup } = this.props;

    const validationUsername = Yup.object().shape({
      username: Yup.string().email(),
    });
    const validateEmail = await validationUsername
      .isValid({
        username: formData.email,
      })
      .then(valid => {
        if (!valid) {
          showError(t('invalidEmail'));
        }
        return valid;
      });
    if (!validateEmail) {
      return;
    }

    const user = {
      name: `${formData.firstName || ''} ${formData.lastName || ''}`,
      username: formData.email,
      password: (formData.password === formData.confirmPassword) ? formData.password : '',
      permissions: {
        features: [],
        contents: [],
      },
    };

    if (user.password === '') {
      showError(t('passwordsDoNotMatch'));
    } else {
      addUser(user, 'Signup')
        .then(() => {
          updateIsSignup(false);
          showSuccess(t('accountCreation'));
          this.setState({ userCreated: true });
        })
        .catch(error => {
          if (error.status === 'Conflict') {
            showError(t('conflictUsername'));
          } else {
            showError(error.status);
          }
          this.setState({ userCreated: false });
        });
    }
  };

  handleKeyUp = e => {
    if (e.key === 'Enter') {
      this.submitForm();
    }
  };

  render() {
    const { isSignupCollapsed, userCreated } = this.state;
    const { t } = this.props;
    const theme = localStorage.getItem('theme');
    const router = loginRouter();

    return (
      !userCreated ? (
        <div className="Login">
          <div className="headerLogin">
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" height="30" />
          </div>
          <div className="middle">
            <div className={`inner ${isSignupCollapsed ? 'smallInner' : ''}`}>
              <img src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} className="app-logo" alt="logo" />
              <form id="Signup__inner__form" className="formulaire">
                <input
                  id="email"
                  type="email"
                  name="email"
                  onKeyUp={this.handleKeyUp}
                  placeholder={t('email')}
                  className={isSignupCollapsed ? 'smallInput' : ''}
                  autoComplete="new-password"
                />
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  onKeyUp={this.handleKeyUp}
                  placeholder={t('firstName')}
                  className={isSignupCollapsed ? 'smallInput' : ''}
                />
                <input
                  id="lastName"
                  type="text"
                  name="lastName"
                  onKeyUp={this.handleKeyUp}
                  placeholder={t('lastName')}
                  className={isSignupCollapsed ? 'smallInput' : ''}
                />
                <input
                  id="password"
                  type="password"
                  name="password"
                  onKeyUp={this.handleKeyUp}
                  placeholder={t('password')}
                  className={isSignupCollapsed ? 'smallInput' : ''}
                />
                <input
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  onKeyUp={this.handleKeyUp}
                  placeholder={t('confirmPassword')}
                  className={isSignupCollapsed ? 'smallInput' : ''}
                />
              </form>
              <br />
              <button
                type="submit"
                className={isSignupCollapsed ? 'smallInput' : ''}
                onClick={this.submitForm}
              >
                {t('confirm')}
              </button>
              <br />
              <Link
                key="/"
                to="/"
                style={{ color: 'white', textAlign: 'center' }}
                onClick={this.handleSignup}
              >
                {t('backToLogin')}
              </Link>
              <br />
            </div>
          </div>
          <Footer />
        </div>
      )
        : (
          <Router history={history}>
            {router}
          </Router>
        )
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addUser: reduxOperations.users.addUser,
    updateIsSignup: reduxOperations.session.updateIsSignup,
  }, dispatch);
}

SignupPage.propTypes = propTypes;

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(SignupPage)));
