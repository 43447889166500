import i18n from 'i18next';
import statusColors from '~styles/statusColors';
import { serverTime } from '~utils/time';

function flattenCauses(causes) {
  return causes.reduce((acc, cause) => acc.concat(cause, flattenCauses(cause.subMenu || [])), []);
}

function getMachineStateInformations(
  aliveDelay,
  stopCauses,
  event,
  lastAlive,
  defaultUnfilledStopCauseColor,
  showAlive = true,
  language,
) {
  const currentTS = serverTime();

  let color = statusColors.Unknown;
  let icon = 'question';
  let timeInMS = 0;
  let text = '-';

  if (showAlive && lastAlive && lastAlive.shutdown) {
    color = statusColors.Shutdown;
    icon = 'power-off';
    timeInMS = Math.max(0, currentTS - lastAlive.timestamp);
  } else if (showAlive && lastAlive && currentTS - lastAlive.timestamp > aliveDelay) {
    color = statusColors.Unavailable;
    timeInMS = Math.max(0, currentTS - lastAlive.timestamp);
  } else if (event) {
    timeInMS = Math.max(0, currentTS - event.timestamp);
    switch (event.status) {
      case 'ON': {
        color = statusColors.On;
        icon = 'santaRun';
        text = i18n.t('operating');
        break;
      }
      case 'OFF': {
        const flattenedStopCauses = stopCauses && flattenCauses(stopCauses);
        const stopCause = flattenedStopCauses && flattenedStopCauses.find(s => s.id === event.stopCauseId);
        color = (stopCause && stopCause.color) || defaultUnfilledStopCauseColor || statusColors.Off;
        icon = 'santaStop';

        if (stopCause) {
          const languageMap = {
            en: stopCause.nameEN,
            fr: stopCause.nameFR,
            es: stopCause.nameES,
          };
          text = languageMap[language] || stopCause.name;
        } else {
          text = i18n.t('stopped');
        }

        break;
      }
      default:
      // do nothing
    }
  }

  return {
    color,
    icon,
    timeInMS,
    text,
  };
}

export {
  getMachineStateInformations,
};
